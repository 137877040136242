import { Subject, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import SampleJSON from '../assets/kinderschuhe-data3.json'

@Injectable()
export class GroessenService {
    groessenEingabe = new Subject;
    selectedTile = new BehaviorSubject([]);
    groessenEingabenDisplayed = new BehaviorSubject([]);
    public obj1:any = {feed: {entry: 'loading'}};
    entries;
    sheetUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTATTXndxbxQ9b-W8tott-4szAbvY-_ZGfu1MsNw2kjB2-tNxrml3vFQ4SfagwignlqLHjGq9PoSMoQ/pubhtml';
    sheetUrlFragen = 'https://spreadsheets.google.com/feeds/list/1DRyxGMhVLG-aI-3XJRv004daQfaI4bd8Mj40Dkfm7o4/o96rjr5/public/full'
    einkommensteuer;
    data;
    keys;
    groesseDE;
    altersgruppe;
    alter;
    groessenverteilung;
    fromFootLengthResult;
    fromAlter;
    groessenListe = new BehaviorSubject([]);
    ageGroup = [];
    fromSystem;
    foundSizes;


    constructor(private http:HttpClient, private ngx:NgxXml2jsonService){}

    onFromFootLength(length:number){
        this.data = SampleJSON;
        const von = this.data[5]
        //console.log(this.data);
        for(let el of this.data){
            
            const keys = Object.values(el);
            //console.log(keys)
            const von = Number(keys[3]);
            const bis = Number(keys[4]);
            
            if(length >= von && length <= bis){
                const spielraum = Number(keys[6])-length
                //console.log(keys[0])
                this.fromFootLengthResult = {
                    groesse: keys[0],
                    altersgruppe: keys[1],
                    groessenverteilung:keys[2],
                    leiste: keys[6],
                    spielraum: spielraum,
                    origLength: length
                }
            }
        }
        //console.log(this.fromFootLengthResult)
        if(this.fromFootLengthResult){
            return this.fromFootLengthResult
        }
    }

    onFromAlter(ageGroup:number){
        this.data = SampleJSON;
        
        //console.log(von);
        for(let el of this.data){
            
            const keys = Object.values(el);
            const spielraum = Number(keys[6])-length
            console.log(keys)
            //console.log(keys)
            const von = Number(keys[13]);
            
            
            if(ageGroup == keys[1]){
                //console.log(keys[0])
                this.fromAlter = {
                    groesse: keys[0],
                    altersgruppe: keys[1],
                    groessenverteilung:keys[2],
                    leiste: keys[6],
                    spielraum: spielraum
                }
                    return this.fromAlter;
            }
        }
        
    }

    extractAgeGroups(){
        this.data = SampleJSON;
        for(let el of this.data){
            const keys = Object.values(el)
            if(keys[1]){
                this.ageGroup.push(keys[1])
                //console.log(keys[1])
            }
        }
        return this.ageGroup
     }

     onSchuhConverter(eingabeSystem, ausgabeSystem, schuhgroesse, aelterAls){
         this.keys = [];
         this.foundSizes = [];
         this.data = SampleJSON;
        //console.log(eingabeSystem)
        //console.log(ausgabeSystem)
        for(let el of this.data){
            this.keys = Object.values(el)
            //console.log(el)
        
        switch (eingabeSystem) {
            case 'uk':
                const von = this.keys[22]
                //console.log(von)
                const bis = this.keys[6]
                if(schuhgroesse >= von && (schuhgroesse-von) < 0.5){
                    this.fromSystem = {
                        groesse: this.keys[0],
                        altersgruppe: this.keys[1],
                        groessenverteilung:this.keys[2],
                        us: this.keys[21],
                        uk: this.keys[22]}
                        console.log(this.fromSystem)
                        this.foundSizes.push(this.fromSystem);
                }
                
            case 'us':
                
                break;
            case 'eur':
                
                break;
            case 'de':
                
                break;     
        
            default:
                break;
        }
     }
     return this.foundSizes
    }
}
