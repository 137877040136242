<!-- 
  file-name: app.component.html
  *****
  The important code for logging-in, signing-up or logging-out, rest code you can manage yourself based on user login/logout scenerio.
  *****
  You can any time check user logging-in/out status in your template using:
  *ngIf="!(authService.user | async)"
-->

...
    <div>
        <!-- this header tag displays only when user is logged-in -->
        <h1 *ngIf="authService.user | async">
	        Welcome User: {{ (authService.user | async)?.email }}!
        </h1>

		<!-- this div displays only when user is not-logged-in -->
	    <div *ngIf="!(authService.user | async)">
            <!-- these input fields takes email and password from user -->
            <input type="text" [(ngModel)]="email" placeholder="Enter email"/>
            <input type="password" [(ngModel)]="password" placeholder="Enter password"/>

            <!-- user click this button to signup -->
            <button (click)="signup()" [disabled]="!email || !password">
            	Signup
            </button>

            <!-- user click this button to login -->
            <button (click)="login()" [disabled]="!email || !password">
            	Login
            </button>

            <!-- user click this button to login -->
            <button (click)="gLogin()">
            	GLogin
            </button>   
        </div>
        
        <div *ngIf="authService.user | async" style="text-align: center;"> 

             <!-- user click this button to logout -->
        <!-- this button displays only when user is logged-in -->
        <button (click)="logout()" *ngIf="authService.user | async">
        	Logout
        </button>

        <button (click)="loadData()" *ngIf="authService.user | async">
            Sort Data (new to old)
        </button>

            <h1>Feedback Data</h1>
            <table style="width:100%" >
                <tr>
                    <th>Altersgruppe</th>
                    <th>User Feedback</th>
                    <th>Groesse</th>
                    <th>Timestamp</th>
                  </tr>
                  <tr *ngFor="let item of data">
                    <td>
                        {{ item.altersgruppe }}
                    </td>
                    <td>
                        {{ item.alterUserFeedback }}
                    </td>
                    <td>
                        {{ item.groesse }}
                    </td>
                    <td>
                        {{ item.timestamp }}
                    </td>
                  </tr>
            </table>

        <br>
        <h1>Sizings</h1>
            <table style="width:100%" >
                <tr>
                    <th>Altersgruppe</th>
                    <th>Groesse</th>
                    <th>Timestamp</th>
                  </tr>
                  <tr *ngFor="let item of dataCalcButton">
                    <td>
                        {{ item.altersgruppe }}
                    </td>
                    <td>
                        {{ item.groesse }}
                    </td>
                    <td>
                        {{ item.timestamp }}
                    </td>
                  </tr>
            </table>

        </div>

       
       
    </div>
