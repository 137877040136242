<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" />
<!-- <div class="container">
  <div class="row">
    <div class="col-md-12 py-3">
      <h2 class="pb-2">Current Events</h2>
      <ngb-carousel *ngIf="event_list">
        <ng-template ngbSlide *ngFor="let event of current_events">
          <img [src]="event.img" alt="Random slide">
          <div class="carousel-caption">
            <h3>{{ event.eventLocation }}</h3>
            <p [innerText]="event.eventDescription"></p>
          </div>
          
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
</div> -->

<div class="container">
  
  <div class="row1">
    
      <ngb-carousel *ngIf="event_list">
        <ng-template ngbSlide *ngFor="let shoe of threeShoes">
          <div class="row1" *ngIf="shoe.large_image">
          <h2>Schuhe in DE/EUR {{shoe['Fashion:size']}} </h2>
          
          {{shoe.merchant_product_second_category}}<br>
          von {{ shoe.brand_name}}
          
          <mat-card-content>
            <!-- <a [href]="shoe.aw_deep_link" target="_blank"> -->
              <img [src]="shoe.large_image" alt="Random slide">
              <!-- </a> -->
            </mat-card-content>
              
          
         <div class="row">
          Normaler Preis:  {{shoe.store_price}} €<br>
         </div>
          
          Neuer Preis:  {{shoe.search_price}} € <mat-icon 
          class="blue-icon"
          matTooltip='Hier sehen Sie ausschliesslich Schuhe in Ihrer Grösse aus insgesamt über 6400 Schuhen von unserem Partner schuhe24.de'
          >
            info</mat-icon>
          </div>
        </ng-template>
      </ngb-carousel>
   
  </div>
</div>
