import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GroessenService } from '../groessen.service';
import { DataStorageService } from '../shared/data-storage.service';
import { ProductExport } from '../shared/product-import.service';


@Component({
  selector: 'app-groessen-eingabe',
  templateUrl: './groessen-eingabe.component.html',
  styleUrls: ['./groessen-eingabe.component.css'],
  
})
export class GroessenEingabeComponent implements OnInit {
 inputForm: FormGroup;
 feedbackForm: FormGroup;
 tileSubscription: Subscription;
 isFusslaenge: boolean = false;
 isSchuhgroesse: boolean = false;
 isAlter:boolean = false;
 isDisplayedSubscription:Subscription;
  data;
  groessenListeSubscription:Subscription;
  groessenListe = [];
  groessenListe2 = [];
  groessenListeLoaded = false;
  successMessage;
  correctAgeGroup;
  feedbackClicked = false;
  productsLoaded = false;
  breakpoint: number;
  rowHeight;

 
 

  constructor(
    private groessenService: GroessenService, 
    private dataStorageService: DataStorageService,
    private productService: ProductExport
    ) { }

  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 2;

    this.inputForm = new FormGroup({
      fusslaenge: new FormControl(),
      system: new FormControl(),
      alter: new FormControl(),
      schuhgroesse: new FormControl(),
      eingabe: new FormControl(),
      ausgabe: new FormControl(),
      aelterAls: new FormControl()
   });
   this.feedbackForm = new FormGroup({
    alterUserFeedback: new FormControl()
 });
   this.isDisplayedSubscription = this.groessenService.groessenEingabenDisplayed.subscribe(res => {
     this.isFusslaenge = res[0];
     this.isAlter = res[1];
     this.isSchuhgroesse = res[2];
   })

   this.tileSubscription = this.groessenService.selectedTile.subscribe((res:any) => {
     //console.log(res)
     switch (res) {
       case 'gemessene Fusslänge':
         this.isFusslaenge = true;
         this.isAlter = false;
         this.isSchuhgroesse = false;
         break;
       case 'Alter':
         this.isAlter = true;
         break;
       case 'Schuhgrösse':
         this.isSchuhgroesse = true;
         break;
     }
   })
   
   this.groessenListeSubscription = this.groessenService.groessenListe.subscribe(res => {
     //this.groessenListe = res;
     
     
     for(let el of res){
       this.groessenListe.push({value: el, viewValue: el})
       
     }
     //console.log(this.groessenListe)
     if(this.groessenListe != []){
     this.groessenListeLoaded =true;
     }
    
   })
  
   
  }

 /*  ngOnDestroy(){
    this.groessenListeSubscription.unsubscribe();
    this.tileSubscription.unsubscribe();
    this.isDisplayedSubscription.unsubscribe();
  } */

  onCalculate(){
    if(this.isFusslaenge){
      this.data = this.groessenService.onFromFootLength(this.inputForm.value.fusslaenge);
      }
    if(this.isAlter){
      this.data = this.groessenService.onFromAlter(this.inputForm.value.alter)
      //console.log(this.inputForm.value.alter)
    }
    if(this.isSchuhgroesse){
      console.log(this.inputForm.value)
      this.data = this.groessenService.onSchuhConverter(this.inputForm.value.eingabe, this.inputForm.value.ausgabe, this.inputForm.value.schuhgroesse, this.inputForm.value.aelterAls)
      console.log(this.data)
    }
    this.data.timestamp = new Date;
    this.dataStorageService.storeDataSelection(this.data)
    //console.log(this.data.groesse)
    this.productService.onGetProductSelection(this.data.groesse)

    this.feedbackClicked = false;
    this.correctAgeGroup = null;
    this.groessenListe2 = [];
  }

  onShowProducts(){
    this.productsLoaded = true;
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 2;
  }

  onYes(){
    this.feedbackClicked = true;
    this.correctAgeGroup = true;
    this.successMessage = "Danke für Ihre Angabe!"
    console.log(this.feedbackClicked)
    console.log(this.correctAgeGroup)
    this.dataStorageService.storeData(this.data)
  }

  onSchablone(){
    const url = "https://kinderschuhe24.ch/wp-content/uploads/2020/04/gro%CC%88ssenschablone_v2.jpg"
    window.open(url, "_blank");
  }

  onNo(){
    this.feedbackClicked = true;
    this.correctAgeGroup = false;
    
    this.getFormatedAgeGroups()

    console.log(this.groessenListe)
    console.log(this.feedbackClicked)
    console.log(this.correctAgeGroup)
  }

  getFormatedAgeGroups(){
    const res = this.groessenService.extractAgeGroups()
    for(let el of res){
      this.groessenListe2.push({value: el, viewValue: el})
      
    }
    //console.log(this.groessenListe)
    if(this.groessenListe2 != []){
    this.groessenListeLoaded =true;
    }
  }

  onCorrectAgeGroup(){
    this.correctAgeGroup = true;
    this.feedbackClicked = true;
    const enteredFootLength = this.inputForm.value.fusslaenge
    const alterUserFeedback = this.feedbackForm.value.alterUserFeedback
    
    
    this.data.alterUserFeedback = alterUserFeedback
    this.data.enteredFootLength = enteredFootLength
    this.groessenListe2 = [];
    
    this.dataStorageService.storeData(this.data)
  }


}
