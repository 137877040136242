import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProductExport } from '../shared/product-import.service';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  threeShoesSubscription:Subscription;
  threeShoes = [];
  event_list = []

  constructor(private productImporter:ProductExport) {
  }

  ngOnInit() {
    this.threeShoesSubscription = this.productImporter.threeShoes.subscribe(res => {
      if(res){
        this.threeShoes = res;
        
      }
      
    })
  }

}