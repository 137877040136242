import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GroessenEingabeComponent } from './groessen-eingabe/groessen-eingabe.component';
import { GroessenausgabeComponent } from './groessenausgabe/groessenausgabe.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DemoMaterialModule } from './material.module';
import { BerechnungAuswahlComponent } from './berechnung-auswahl/berechnung-auswahl.component';
import { GroessenService } from './groessen.service';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ProductExport } from './shared/product-import.service';
import { CarouselComponent } from './carousel/carousel.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { Slider } from './slider/slider.component';
import { AnalysisComponent } from './analysis/analysis/analysis.component';
import { AuthService } from './shared/auth.service';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';

@NgModule({
  declarations: [
    AppComponent,
    GroessenEingabeComponent,
    GroessenausgabeComponent,
    BerechnungAuswahlComponent,
    CarouselComponent,
    Slider,
    routingComponents,
    PagenotfoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // Only required for database features
    AngularFireDatabaseModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    NgbModule
  ],
  providers: [GroessenService, ProductExport, AuthService, AngularFirestore],
  bootstrap: [AppComponent]
})
export class AppModule { }
