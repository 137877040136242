<button (click)=onReset()><</button>
<mat-grid-list *ngIf="isSelected" [cols]="cols" rowHeight="2:1">
    <mat-grid-tile 
        *ngFor="let el of this.data;let i = index;"
        (click)="onClick(el)" 
        (mouseenter)="mouseEnter(el)" 
        [ngStyle]="myStyles(el)" 
        (mouseleave)="mouseLeave(el)"
        >
        
        <div class="row">
             {{el}}
        </div>
        
    </mat-grid-tile>
</mat-grid-list>

