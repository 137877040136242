import { Component, OnInit } from '@angular/core';
import { AngularFireModule, FirebaseApp } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreModule } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/auth.service';
import { DataStorageService } from 'src/app/shared/data-storage.service';
import firebase from 'firebase/app';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/database';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.css']

})
export class AnalysisComponent implements OnInit {
  email: string;
  password: string;
  data;
  data1;
  sortedData;
  dataCalcButton;
  item$:  Observable<any[]>;
  

  constructor(
    public dataService:DataStorageService,
    private db:AngularFirestore,
    public authService:AuthService,
    public afAuth:AngularFireAuth,
    private db2:AngularFireDatabase
    ) {
      /* console.log(authService) */
      db2.list('inputData').valueChanges().subscribe(courses => {
         // Check the returned values;
          this.data = courses;
          /* console.log(courses) */
        })
        db2.list('calculateButton').valueChanges().subscribe(courses => {
          // Check the returned values;
           this.dataCalcButton = courses;
           console.log(courses)
         })
        
        
      }
    /* this.afAuth.AngularFireAuth.user; */
    /* this.item$ = db.collection('inputData').valueChanges(); */
    

  ngOnInit(): void {
    
  }

  signup() {
    this.authService.signup(this.email, this.password);
    this.email = this.password = '';
  }

  login() {
    this.authService.login(this.email, this.password);
    this.email = this.password = '';    
  }

  gLogin() {
    this.authService.gLogin();
     
  }

  loadData(){
    // console.log(this.authService.user)
    //console.log(this.dataService.fetchData())
/*     this.data = this.dataService.fetchData();*/
   /*  for(let item of this.data){
      this.data1.
    } */
    this.data.sort((b,a) => a.timestamp.localeCompare(b.timestamp));
    this.dataCalcButton.sort((b,a) => a.timestamp.localeCompare(b.timestamp));
  /*   console.log(this.data1) */
          
          /* this.data1.forEach(function(value:any){
          this.data.push(value.altersgruppe)
          console.log(value)
          }) */
    

  /*   for(let key in this.data){
      //this.sortedData.altersgruppe.push(sizing)
      console.log(this.data[key])
    } */
    
  }

  logout() {
    this.authService.logout();
  }

  


}
