<form [formGroup]="inputForm">

    <mat-card>
      
      <mat-card-content *ngIf="isFusslaenge">
        <mat-form-field>
          <mat-label>Fusslänge eingeben</mat-label>
          <input 
          matInput placeholder="in cm" 
          formControlName="fusslaenge" 
          required
          >

        </mat-form-field>
        <h3 *ngIf="!this.data">Bitte geben Sie die gemessene oder ungefähre Fußlänge ein um die Schuhschablone direkt herunterzuladen.</h3>
    </mat-card-content>
        <br>
      <mat-card-content *ngIf="isSchuhgroesse">
        <mat-form-field >
            <mat-label >Schuhgrösse</mat-label>
            <input 
            matInput placeholder="in cm" 
            formControlName="schuhgroesse" 
            required
            >
  
          </mat-form-field>
        </mat-card-content>
          <br>
          <mat-card-content *ngIf="isAlter">
          <mat-form-field *ngIf="groessenListeLoaded">
            <mat-label>Alter wählen</mat-label>
            <mat-select formControlName="alter">
              <mat-option *ngFor="let el of groessenListe" [value]="el.value">
                {{el.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>

          <mat-card-content *ngIf="isSchuhgroesse">
            <mat-form-field *ngIf="groessenListeLoaded">
              <mat-label>Alter wählen</mat-label>
              <mat-select formControlName="alter">
                <mat-option *ngFor="let el of groessenListe; let i = index" [value]="el.value">
                  {{el.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>

        </mat-card-content>
    
          <mat-card-content *ngIf="isSchuhgroesse">
            <mat-label>Eingabe Grössensystem</mat-label>
            
          <mat-radio-group formControlName="eingabe">
            <mat-radio-button matTooltip="US-Schuhgrößentabelle für Kinder gemäß ISO-Spezifikation ISO/TS 19407:2015" class="example-margin" value="us">US</mat-radio-button>
            <mat-radio-button class="example-margin" value="uk">UK</mat-radio-button>
            <mat-radio-button class="example-margin" value="eur">EUR</mat-radio-button>
            <mat-radio-button class="example-margin" value="de">DE</mat-radio-button>
          </mat-radio-group>
          <br><br><mat-divider></mat-divider><br>
          <mat-label>Ausgabe Grössensystem</mat-label>
            
          <mat-radio-group formControlName="ausgabe">
            <mat-radio-button class="example-margin" value="us">US</mat-radio-button>
            <mat-radio-button class="example-margin" value="uk">UK</mat-radio-button>
            <mat-radio-button class="example-margin" value="eur">EUR</mat-radio-button>
            <mat-radio-button class="example-margin" value="de">DE</mat-radio-button>
          </mat-radio-group>
        
        </mat-card-content>


        <button class="app-button" mat-raised-button color="primary" (click)="onCalculate()" *ngIf="isFusslaenge || isAlter">Berechnen</button>
      </mat-card>
    </form>
   

    <mat-card>
    <mat-grid-list [cols]="breakpoint" (window:resize)="onResize($event)" [rowHeight]="'450px'" [gutterSize]="'5px'">
      <mat-grid-tile>
   
      <mat-card-content *ngIf="this.data">
      <mat-card-content *ngIf="!this.isAlter">Eingegebene Fusslänge: {{this.inputForm.value.fusslaenge}} cm</mat-card-content>
      <mat-card-content>
        <mat-icon 
        class="blue-icon"
        matTooltip='In Deutschland wurden Leistengrößen ursprünglich direkt in Zentimetern angegeben, z. B. Größe 29 ⅓", es hat sich aber die Verwendung des französischen Stichs europaweit durchgesetzt. Dafür wird die Leistenlängen durch 0.67 cm geteilt.'
        >
          info</mat-icon> 
        Typische Grösse DE/EUR: {{this.data.groesse}}
        
        <br>
      </mat-card-content>
        
      <mat-card-content>
        <mat-icon 
        class="blue-icon"
        matTooltip='Die Leistenlänge ist in der Regel 1 bis 1.5 Zentimeter länger als die Fußlänge.'
        > info</mat-icon> 
        Typische Leistenlänge: {{this.data.leiste}} cm</mat-card-content>
      <mat-card-content *ngIf="!this.isAlter">
        <mat-icon 
        class="blue-icon"
        matTooltip='Der typische Spielraum kann berechnet werden indem Fusslänge von Leistenlänge abgezogen wird.'
        >
          info</mat-icon> 
        Spielraum: {{this.data.spielraum | number}} cm</mat-card-content>
      <mat-card-content>Altersgruppe: {{this.data.altersgruppe}}</mat-card-content>
      <mat-card-content>Grössenverteilung der Fußlängen in dieser Altersgruppe: {{this.data.groessenverteilung}}</mat-card-content>
      <mat-card-content *ngIf="!feedbackClicked">
        Ist Ihr Kind in dem angegebenen Altersbereich?
        <mat-icon 
        class="blue-icon"
        matTooltip='Mit dieser Angabe dürfen Sie uns helfen die berechneten Ergebnisse zu verbessern.'
        >
          info</mat-icon> 
        <button class="app-button" mat-raised-button color="primary" (click)="onYes()">Ja</button>
        <button class="app-button" mat-raised-button color="primary" (click)="onNo()">Nein</button>
      </mat-card-content>
      <mat-card-content *ngIf="feedbackClicked && correctAgeGroup">
        <div class="row1">
          {{this.successMessage}}
          <button class="app-button" mat-raised-button color="primary" (click)="onSchablone()">Schuhschablone herunterladen</button>
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="!correctAgeGroup && feedbackClicked">
        Das tut uns leid.
        <form [formGroup]="feedbackForm">
          <mat-form-field *ngIf="groessenListeLoaded">
            <mat-label>korrektes Alter wählen</mat-label>
            <mat-select formControlName="alterUserFeedback">
              <mat-option *ngFor="let el of groessenListe2; let i = index" [value]="el.value">
                {{el.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button class="app-button" mat-raised-button color="primary" (click)="onCorrectAgeGroup()">Alter bestätigen</button>
        </form>
        <form action="https://www.paypal.com/donate" method="post" target="_top">
          <input type="hidden" name="hosted_button_id" value="U4NBCHPSWF552" />
          <input type="image" src="https://www.paypalobjects.com/de_DE/CH/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
          <img alt="" border="0" src="https://www.paypal.com/de_CH/i/scr/pixel.gif" width="1" height="1" />
          </form>
          

      </mat-card-content>
    </mat-card-content>
  
    </mat-grid-tile>
    
      <!-- <mat-grid-tile *ngIf="this.productsLoaded">
        <app-slider ></app-slider>
      </mat-grid-tile> -->
      
  </mat-grid-list>
</mat-card>
