import { Component, OnInit } from '@angular/core';
import { GroessenService } from '../groessen.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-berechnung-auswahl',
  templateUrl: './berechnung-auswahl.component.html',
  styleUrls: ['./berechnung-auswahl.component.css']
})
export class BerechnungAuswahlComponent implements OnInit {
data;
currentTile;
cols;
tileSubcription:Subscription;
isSelected:boolean = true;
groessenListeSubscription:Subscription;
groessenListe;

  constructor(private groessenService:GroessenService, public router:Router) { }

  ngOnInit() {
    this.data = ['gemessene Fusslänge', 'Alter']
    this.cols = this.data.length;

    
  }
  
  onClick(el){
    this.groessenService.selectedTile.next(el);
    this.isSelected = false;
    //console.log(el);
    if(el == 'Alter' ||  el == 'Schuhgrösse'){
      this.groessenListe = this.groessenService.extractAgeGroups()
      this.groessenService.groessenListe.next(this.groessenListe.slice());
    }
  }

  onReset(){
    this.isSelected = true;
    this.groessenService.groessenEingabenDisplayed.next([false, false, false])
  }

  mouseEnter(tile){
    //console.log('MouseEnter'+tile);
    this.currentTile = tile;
  }

  mouseLeave(tile){
    //console.log('MouseLeave'+tile);
    this.currentTile = null;
  }

  myStyles(tile): any {
    let color = 'orange';
    if(tile == this.currentTile){
      return {
          'border':'2px',
          'border-style': 'dotted',
          'border-color': 'orange',
          'margin-left': '-10px',
          'margin-right': '-10px'
      };
    }
  }

}
