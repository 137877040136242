import products from '../../assets/shoes24-sizes-products.json'
import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class ProductExport {
    products:any = products;
    size;
    allProducts = [];
    ctr;
    finalProducts;
    threeShoes = new BehaviorSubject([])
    amountProducts;


    onGetProductSelection(sizeUser: Number){
    
        
        if(this.products){
            for(var i = 0; i < this.products.length; i++){
                
                const size = Number(this.products[i].size)
                this.allProducts = [];
                this.amountProducts = 0;
                if(size == sizeUser){
                    
                    
                    this.allProducts = this.products[i].products


                   
                    this.amountProducts = this.allProducts.length
                    if(this.amountProducts > 50){
                        this.amountProducts = 50;
                    }

                    
                        const productsBeforeShuffle = this.allProducts
                        const shuffeledProducts = this.shuffle(productsBeforeShuffle)
                        this.finalProducts = shuffeledProducts.slice(0, this.amountProducts)
                        //console.log(this.finalProducts)
                        //console.log(this.allProducts)
                    
                }
            }
            
        }
        this.threeShoes.next(this.finalProducts)
        return this.finalProducts 
    }

    shuffle(arra1) {
        this.ctr = 15
       
    // While there are elements in the array
       for(var i = 1; i < this.ctr; i++){
    // Pick a random index
            const index = Math.floor(Math.random() * this.ctr);
    // Decrease ctr by 1
            const ctr = this.ctr-1;
    // And swap the last element with it
            const temp = arra1[this.ctr];
            arra1[this.ctr] = arra1[index];
            arra1[index] = temp;
        }
        return arra1;
    }

    onImageExists(image_url){

            var http = new XMLHttpRequest();
        
            http.open('HEAD', image_url, false);
            http.send();
        
            return http.status != 404;
        
    }
    
}