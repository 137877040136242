import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
/* import { RecipeService } from '../recipes/recipe.service';  */
import {map, tap, exhaustMap, take} from 'rxjs/operators';
import { FirebaseApp } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
/* import { AuthService } from '../auth/auth.service'; */



@Injectable({providedIn: 'root'})
export class DataStorageService{
    _db:AngularFirestore;
item$:  Observable<any[]>;

    constructor(
        private http: HttpClient,
        public afAuth: AngularFireAuth,
        private db: AngularFirestore) {};

storeData(data){
    
    this.http
    .post('https://schuhgroesse-dev-default-rtdb.firebaseio.com/inputData.json', data)
    .subscribe(response => {
        //console.log(response)
    });
}

storeDataSelection(data){
    
    this.http
    .post('https://schuhgroesse-dev-default-rtdb.firebaseio.com/calculateButton.json', data)
    .subscribe(response => {
        //console.log(response)
    });
}

 fetchData(){
    this.http
    .get('https://schuhgroesse-dev-default-rtdb.firebaseio.com/calculateButton.json')
    .subscribe(response => {
        console.log(response)
    });
} 

fetchData2(){
    var data = this._db.collection('inputData').valueChanges()
}

/* fetchData(){
    return this.authService.user.pipe(
        take(1), 
        exhaustMap(user => {
            
        return this.http.get<Calculators[]>(
            'https://ng-course-recipe-book-9f4cb.firebaseio.com/recipes.json'
            )
        .pipe(
        
        map(recipes => {
            return recipes.map(recipe => {
                return {
                    ...recipe,
                     ingredients: recipe.ingredients ? recipe.ingredients : [] 
                };
            });
        }),
        tap(recipes => {
                this.recipeService.setRecipes(recipes);
            })
        );
    }
        )) */
}


